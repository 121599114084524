@import 'src/utils/utils';

.button {
  @include Text-16-reg;
  position: relative;
  display: inline-grid;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 8px;
  padding-right: 20px;
  padding-bottom: 8px;
  padding-left: 20px;
  border: 0;
  border-radius: 10px;
  text-decoration: none;
  color: $Main-2;
  background-color: $Corporate-2;
  transition:
    opacity 0.3s,
    color 0.3s,
    background-color 0.3s,
    outline-color 0.3s;
  grid-auto-flow: column;
  column-gap: 10px;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;

  @media(min-width: $lg) {
    min-height: 55px;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 8px;
    padding-left: 20px;
  }

  &:not(:disabled):not(.button--transparent):not(.button--white):not(.button--border):hover {
    @media(min-width: $lg) {
      background-color: $Corporate-1;
    }
  }

  &:not(:disabled):active {
    opacity: 0.7;
  }

  &:disabled {
    color: $Main-6;
    background-color: $Main-4;
    cursor: not-allowed;
  }

  svg {
    fill: currentcolor;
    stroke: currentcolor;
  }

  &--transparent {
    color: $Main-1;
    background-color: transparent;
    outline: 1px solid $Main-5;
    outline-offset: -1px;

    &:not(:disabled):hover {
      @media(min-width: $lg) {
        outline-color: $Main-1;
      }
    }
  }

  &--white {
    color: $Main-1;
    background-color: $Main-2;

    &:not(:disabled):hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      fill: $Corporate-2;
      stroke: $Corporate-2;
    }
  }

  &--small {
    @include Text-14-reg;
    min-height: 33px;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 10px;
    gap: 5px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}